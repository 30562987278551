import React, { useState, useEffect } from 'react';
//import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Utilities from './../../../../utils/api';
import { useHistory } from 'react-router-dom';
import { Grid, Button, TextField, Link, Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  socialButtons: {
    justifyContent: 'flex-end',
    paddingRight: '3rem'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  const [notifications] = useState([]);
  const browserHistory = useHistory();

  const logout = event => {
    Utilities.clearSession();
    browserHistory.push('/');
  };

  const handleChange = event => {
    props.handleChange(event);
  };

  const [languages, setLanguages] = useState([]);
  const langKey = localStorage.getItem('langKey');

  useEffect(() => {
    localStorage.setItem('langKey', props.lang);

    async function requestLanguages() {
      const languagesArray = await Utilities.MakeAxiosRequest(
        '/anatomy_api/languages',
        'GET',
        null,
        null,
        null
      );

      console.log(languagesArray, 'hello');

      setLanguages(languagesArray.response);
    }

    requestLanguages();
  }, [langKey]);

  const renderMenuItem = () => {
    return languages.map(item => (
      <MenuItem value={item['language_code']}>{item.title}</MenuItem>
    ));
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        {/* <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/logo--white.svg"
          />
        </RouterLink> */}
        {/* <IconButton
          color="inherit"
          onClick={onSidebarOpen}
        >
          <MenuIcon />
        </IconButton> */}
        <div className={classes.flexGrow} />
        <Grid
          className={classes.socialButtons}
          container
          spacing={2}
        >
          <Grid item>
            <a
              href="https://testflight.mri.life/"
              target="_blank"
            >
              <Button
                size="small"
                variant="contained"
              >
                Testflight Dashboard
              </Button>
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://mri.life/"
              target="_blank"
            >
              <Button
                size="small"
                variant="contained"
              >
                Production Dashboard
              </Button>
            </a>
          </Grid>
          <Grid item />
        </Grid>
        <Hidden mdDown>
          <FormControl className={classes.formControl}>
            <Select
              id="lang"
              labelId="lang-label"
              lassName={classes.select}
              onChange={handleChange}
              style={{
                backgroundColor: 'rgb(255, 255, 255)',
                padding: '0 5px',
                borderRadius: '2px'
              }}
              value={props.lang}
            >
              {renderMenuItem()}
            </Select>
          </FormControl>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={logout}
          >
            <InputIcon />
            <p style={{ fontSize: '14px', marginLeft: '6px' }}>Sign Out</p>
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
