import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import AddCategoriesIcon from '@material-ui/icons/Add';
import { SearchInput } from 'components';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginLeft: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  addButton: {
    margin: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  leftIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    fontSize: 24
  }
}));

const CategoriesToolbar = props => {
  const { className, parent_data, ...rest } = props;
  const classes = useStyles();
  const browserHistory = useHistory();
  const addCategory = event => {
    browserHistory.push({
      pathname: '/add_category',
      data: parent_data['id']
    });
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          onClick={addCategory}
          variant="contained"
        >
          <AddCategoriesIcon className={classes.leftIcon} /> ADD CATEGORY
        </Button>
        {/* <Button variant="contained" className={classes.exportButton} color="secondary">
          GO BACK
</Button> */}
      </div>
    </div>
  );
};

CategoriesToolbar.propTypes = {
  className: PropTypes.string,
  parent_data: PropTypes.any.isRequired
};

export default CategoriesToolbar;
