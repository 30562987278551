import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel
} from '@material-ui/core';
import AddCategoriesIcon from '@material-ui/icons/Add';
import { SearchInput } from 'components';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import Utilities from '../../../../utils/api';

function Alert(props) {
  return <MuiAlert
    elevation={6}
    variant="filled"
    {...props}
         />;
}

const schema = {
  title: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },

  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginLeft: theme.spacing(1)
  },
  addButton: {
    margin: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  leftIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    fontSize: 24
  }
}));

const AddCategoryToolbar = props => {
  console.log(props);
  const { parent_data_id, className, ...rest } = props;

  const [title, setTitle] = useState((props.data && props.data.title) || '');
  const [languageId, setLanguageId] = useState(0);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('error');

  const [file, setFile] = useState();

  const [btnFlag, setBtnFlag] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  const [languages, setLanguages] = useState([]);

  async function requestLanguages() {
    const languages = await Utilities.MakeAxiosRequest(
      '/anatomy_api/languages',
      'GET',
      null,
      null,
      null
    );

    let languagesArr = [];
    if (languages.status) {
      if (languages.response) {
        for (var i = 0; i < languages.response.length; i++) {
          languagesArr.push({
            id: languages.response[i].id,
            language_code: languages.response[i].language_code,
            title: languages.response[i].title
          });
        }
        setLanguages(languagesArr);
        if (languagesArr.length > 0) {
          setLanguageId(2);
        }
        // localStorage.setItem('languages', JSON.stringify(languagesArr));
      }
    }
  }

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
    requestLanguages();
  }, [formState.values]);

  useEffect(() => {
    if (props.data && props.data.id) {
      setBtnFlag(false);
    } else {
      title.length > 0 && file ? setBtnFlag(false) : setBtnFlag(true);
    }
  });

  const handleChange = event => {
    console.log(event.target);
    if (event.target.name === 'title') {
      setTitle(event.target.value);
    } else if (event.target.name === 'language_id') {
      console.log(event.target.value);
      setLanguageId(event.target.value);
    } else {
      setFile(event.target.files[0]);
    }

    // event.persist();
    // setFormState(formState => ({
    //   ...formState,
    //   values: {
    //     ...formState.values,
    //     [event.target.name]:
    //       event.target.type === 'checkbox'
    //         ? event.target.checked
    //         : event.target.value
    //   },
    //   touched: {
    //     ...formState.touched,
    //     [event.target.name]: true
    //   }
    // }));
  };

  const browserHistory = useHistory();

  const goBack = event => {
    browserHistory.goBack();
  };

  const classes = useStyles();

  const handleSubmit = event => {
    event.preventDefault();

    let formData = new FormData();

    let postJson = {};

    if (props.data && props.data.id) {
      postJson = {
        id: props.data.id,
        title: title,
        language_id: languageId
      };
    } else {
      postJson = {
        title: title,
        parent_id: parent_data_id,
        language_id: languageId
      };
    }
    console.log(postJson);

    formData.append('data', JSON.stringify(postJson));
    formData.append('files.sketch', file);

    if (props.data && props.data.id) {
      (async data => {
        // code goes here
        let postResp = await Utilities.MakeAxiosRequest(
          `/anatomy_api/article-groups/${props.data.id}`,
          'PUT',
          null,
          formData,
          null
        );
        if (postResp.status) {
          setAlertMessage('Category Updated');
          setSeverity('success');
          setOpen(true);
        } else {
          setAlertMessage('Failed to update category');
          setSeverity('error');
          setOpen(true);
        }
      })(formData);
    } else {
      (async data => {
        // code goes here
        let postResp = await Utilities.MakeAxiosRequest(
          '/anatomy_api/article-groups',
          'POST',
          null,
          formData,
          null
        );
        if (postResp.status) {
          setAlertMessage('Category Created Successfully');
          setSeverity('success');
          setOpen(true);
        } else {
          setAlertMessage('Failed to create new Category');
          setSeverity('error');
          setOpen(true);
        }
      })(formData);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const renderImage = () => {
    if (props.data && props.data.imageURI) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem'
          }}
        >
          <img
            alt="title"
            src={props.data.imageURI}
            style={{ width: '6rem' }}
          />
        </div>
      );
    }
  };

  return (
    <div className={classes.root}>
      <Snackbar
        autoHideDuration={3000}
        onClose={handleClose}
        open={open}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit}>
        <div
          {...rest}
          className={clsx(classes.root, className)}
        >
          <div className={classes.row}>
            <span className={classes.spacer} />
            {/* <Button
          color="primary"
          variant="contained"
        >
          <AddCategoriesIcon className={classes.importButton} /> ADD CATEGORY
        </Button> */}
            <Button
              className={classes.searchInput}
              color="secondary"
              onClick={goBack}
              variant="contained"
            >
              GO BACK
            </Button>
          </div>

          <div className={classes.row} />

          <div
            className={classes.row}
            style={{ marginTop: '20px' }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Language
                  </InputLabel>
                  <Select
                    inputProps={{
                      name: 'language_id',
                      id: 'language_id'
                    }}
                    label="Language"
                    native
                    onChange={handleChange}
                    value={languageId}
                  >
                    {languages.map(item => (
                      <option
                        key={item['id']}
                        value={item['id']}
                      >
                        {item['title']}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  onChange={handleChange}
                  type="text"
                  value={title}
                  variant="outlined"
                />
              </Grid>

              <Grid
                item
                xs={6}
              >
                <TextField
                  fullWidth
                  // label="Sketch"
                  name="sketch"
                  onChange={handleChange}
                  type="file"
                  //value={formState.values.password || ''}
                  variant="outlined"
                />
                {renderImage()}
              </Grid>
            </Grid>
          </div>

          <div
            className={classes.row}
            style={{ marginTop: '60px', marginLeft: '-8px' }}
          >
            <Grid
              alignItems="center"
              container
              direction="row"
              justify="flex-start"
              spacing={1}
            >
              <Grid
                item
                xs={12}
              >
                <Button
                  className={classes.searchInput}
                  color="primary"
                  // onClick={goBack}
                  disabled={btnFlag}
                  type="submit"
                  variant="contained"
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </form>
    </div>
  );
};

AddCategoryToolbar.propTypes = {
  className: PropTypes.string,
  parent_data_id: PropTypes.any
};

export default AddCategoryToolbar;
