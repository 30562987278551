import React, { useState } from 'react';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/styles';
import Modal from '@material-ui/core/Modal';

import Utilities from '../../utils/api';

import { CloseRounded } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  card: {
    border: '1px solid #3f51b5',
    marginTop: theme.spacing(2),
    borderRadius: '5%',
    overflow: 'hidden',
    maxWidth: '250px',
    position: 'relative',
    cursor: 'pointer'
  },
  content: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  image: {
    width: '100%',
    height: '180px',
    objectFit: 'cover'
  },
  text: {
    color: '#37474f',
    fontFamily: 'Roboto, Helvetica, Ari'
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    color: '#37474f',
    fontFamily: 'Roboto, Helvetica, Ari',
    fontSize: '13px',
    flexDirection: 'column'
  },
  switchText: {
    color: '#3f51b5',
    marginTop: '-3px'
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: '#fff',
    cursor: 'pointer'
  }
}));

const Card = ({
  title,
  imageURI,
  is_published,
  id,
  setSeverity,
  setAlertMessage,
  setOpen,
  history,
  parentID,
  handleDelete
}) => {
  const [state, setState] = useState({
    checkedA: is_published
  });

  function handleCardDelete(e) {
    handleDelete(e, id);
  }

  function onCardClick(e) {
    console.log(history, 'hello');
    history.push({
      pathname: '/add_category',
      state: { id, title, parentID, imageURI }
    });
  }

  function handleChange(event) {
    event.stopPropagation();
    const flag = event.target.checked;

    (async () => {
      const data = { id, publish: flag };
      let response = await Utilities.MakeAxiosRequest(
        '/anatomy_api/publishCategory',
        'POST',
        null,
        data,
        null
      );

      if (response.status) {
        setState({ ...state, checkedA: flag });
      } else {
        setAlertMessage('Failed to Publish');
        setSeverity('error');
        setOpen(true);
      }
    })();
  }

  const classes = useStyles();

  console.log(state);

  return (
    <div className={classes.card}>
      <img
        alt={title}
        className={classes.image}
        onClick={onCardClick}
        src={imageURI}
      />
      <div className={classes.content}>
        <p className={classes.text}>{title}</p>
        <div className={classes.switch}>
          <Switch
            checked={state.checkedA}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            name="checkedA"
            onChange={handleChange}
          />
          <p className={classes.switchText}>Publish</p>
        </div>
      </div>

      <button
        onClick={e => handleCardDelete(e)}
        type="button"
      >
        <CloseRounded className={classes.close} />
      </button>
    </div>
  );
};

export default Card;
