import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import AddCategoryToolbar from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const AddCategory = props => {
  console.log(props);
  const { parent_data, ...rest } = props;
  const browserHistory = useHistory();
  const parent_data_id = browserHistory.location['data'];
  console.log('From add category page');
  console.log(browserHistory);
  const classes = useStyles();

  //const [users] = useState(mockData);

  return (
    <div className={classes.root}>
      <AddCategoryToolbar
        parent_data_id={parent_data_id}
        data={props.location.state}
      />
      <div className={classes.content}>
        {/* <CategoriesTable users={users} /> */}
      </div>
    </div>
  );
};

AddCategory.propTypes = {
  parent_data: PropTypes.any
};

export default AddCategory;
