import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';

import Card from './Card';

import { CategroiesToolbar, CategoriesTable } from './components';
import mockData from './data';
//import categories from './useCategories';
import axios from 'axios';
import Utilities from '../../utils/api';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(14rem, 1fr));',
    gridGap: '1rem',
    marginTop: theme.spacing(5)
  }
}));

const CategoriesList = () => {
  const classes = useStyles();
  const [users] = useState(mockData);
  const [categories, setCategories] = useState([]);
  const [parentCat, setParentCategory] = useState({});
  


  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('error');

  const history = useHistory();

  async function requestCategories() {
    let query = {
      language_code: localStorage.getItem('langKey')
    };
    const categoriesCopy = await Utilities.MakeAxiosRequest(
      '/anatomy_api/first-article-groups',
      'GET',
      'json',
      query,
      null
    );

    if (categoriesCopy.status) {
      if (categoriesCopy.response['children']) {
        setCategories(categoriesCopy.response.children);
      }
      if (categoriesCopy.response['parent']) {
        setParentCategory(categoriesCopy.response.parent);
      }
    }

    console.log(categories.response);
  }

  const lang = localStorage.getItem('langKey');

  useEffect(() => {
    requestCategories();
    //requestLanguages();
  }, [categories.response, lang]);

  async function handleDelete(e, id) {
    e.stopPropagation();
    await Utilities.MakeAxiosRequest(
      `/anatomy_api/article-groups/${id}`,
      'DELETE',
      null,
      null,
      null
    );

    requestCategories();
  }

  function renderCard() {
    const sortedCat = categories.sort((a, b) => {
      return a.id - b.id;
    });

    return sortedCat.map(item => {
      console.log(item, item);
      return (
        <Card
          handleDelete={handleDelete}
          history={history}
          id={item.id}
          imageURI={item.s3ImageUri}
          is_published={item.is_published}
          key={`card-${item.id}`}
          parentID={item.parent_id}
          setAlertMessage={setAlertMessage}
          setOpen={setOpen}
          setSeverity={setSeverity}
          title={item.title}
        />
      );
    });
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function Alert(props) {
    return <MuiAlert
      elevation={6}
      variant="filled"
      {...props}
    />;
  }

  return (
    <div className={classes.root}>
      <Snackbar
        autoHideDuration={3000}
        onClose={handleClose}
        open={open}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      <CategroiesToolbar parent_data={parentCat} />

      <div className={classes.cardContainer}>{renderCard()}</div>
    </div>
  );
};

export default CategoriesList;
