import axios from "axios";

const Utilities = {
    MakeAxiosRequest: async function (path, method = 'GET', respType = 'json', data = {}, headers = null) {
        const result = {
            status: false,
            response: null
        };
        if (headers === null) {
            headers = this.getAuthHeaders();
        }

        path = process.env.REACT_APP_API_URL + path;
        console.log(path);

        const options = {
            method: method,
            url: path,
            responseType: respType,
            //data: data,
            headers: headers
        };

        if (method === 'GET') {
            options['params'] = data;
        } else {
            options['data'] = data;
        }

        return await axios(options)
            .then(function (response) {
                result.status = true;
                result.response = response.data;
                return result;
            })
            .catch(error => {
                result.response = error.response;
                return result;
            });
    },

    getAuthHeaders: function () {
        let token = localStorage.getItem('access_token');
        if (token) {
            return {
                authorization: `Bearer ${token}`
            }
        } else {
            return null;
        }
    },

    isLoggedIn: function () {
        let token = localStorage.getItem('access_token');
        if (token) {
            return true
        } else {
            return false;
        }
    },

    clearSession: function () {
        localStorage.clear();
    }
}

export default Utilities;